.forwardback {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 123px;
    height: 44px;
    margin: 10px;
    background: rgba(70, 106, 233, 0.1);
    border: 1px solid rgba(70, 106, 233, 0.1);
    border-radius: 8px;
    grid-row: 1;
    grid-column: 1;
}

.forwardback:hover {
    background: #4669e97e;
    cursor: pointer;
}

.infoGrid {
    display: grid;
    grid-template-columns: 0fr 1fr;
}

.backIcon {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.forwardbackText {
    width: 43px;
    height: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #466AE9;
    flex: none;
    order: 1;
    flex-grow: 0;
}


.buttonsGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    height: 44px;
    grid-column: 2;
    grid-row: 1;
    justify-self: flex-end;
    margin: 10px;
}

.logs {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 105px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.logs:hover {
    background: #466AE91A;
    cursor: pointer;
}


.logsIcon {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.logsText {
    width: 25px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.quene {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 282px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.quene:hover {
    background: #466AE91A;
    cursor: pointer;

}

.copyCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 282px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}

.copyCard:hover {
    background: #466AE91A;
    cursor: pointer;

}

.copyCardText {

    height: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;

    height: 57px;
    grid-row: 2;
    grid-column: span 2;
    margin: 10px;
    max-width: 440px;

}

.owner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 380px;
    height: 32px;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.ownerText {

    width: auto;
    height: 31px;

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;

    color: #252522;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.ownerLogoonline {
    /* Frame 72 */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;

    width: 64px;
    height: 32px;

    background: rgba(63, 194, 163, 0.15);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.ownerLogowarn {
    /* Frame 72 */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;

    width: 64px;
    height: 32px;

    background: rgba(149, 151, 159, 0.15);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.ownerLogoproblem {
    /* Frame 72 */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;

    width: 64px;
    height: 32px;

    background: rgba(233, 70, 80, 0.15);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.ownerLogoWarp {
    /* Frame 9 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 32px;
    height: 16px;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.ellipse {
    /* Ellipse 5 */

    width: 8px;
    height: 8px;

    background: #3FC2A3;
    flex: none;
    order: 0;
    flex-grow: 0;

}


.address {

    width: auto;
    height: 21px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    color: rgba(37, 37, 34, 0.6);

    flex: none;
    order: 1;
    flex-grow: 0;

}

.device {
    /* Frame 58 */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    width: auto;
    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 3;
    grid-column: span 2;

    margin: 10px;
    max-width: 440px;

}

.deviceTitle {

    width: auto;
    height: 23px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    color: #252522;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.deviceIot {
    /* Frame 59 */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    width: auto;
    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 4;
    grid-column: span 2;

    margin: 10px;
    max-width: 440px;


}

.paidInfo {
    /* Frame 61 */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    width: auto;
    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 5;
    grid-column: span 2;

    margin: 10px;
    max-width: 440px;

}

.data {
    /* Frame 60 */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    width: auto;


    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 6;
    grid-column: span 2;

    margin: 10px;
    max-width: 440px;
}

.list {
    /* list */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    width: auto;
    height: 274px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}

.listItem {
    /* Frame 62 */



    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 0px 8px;
    gap: 20px;

    width: auto;

    border-bottom: 1px solid rgba(37, 37, 34, 0.15);
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.listItem:last-child {
    border-bottom: none;

}

.listItemKey {

    width: 175px;
    height: auto;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(37, 37, 34, 0.6);

    flex: none;
    order: 0;
    flex-grow: 0;

}

.listItemValue {

    width: 203px;
    height: auto;

    word-break: break-word;


    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 1;
    flex-grow: 1;

}

.editButton {
    /* Frame 15 */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    margin-bottom: 20px;

    width: auto;
    height: 44px;


    background: #466AE9;
    border-radius: 8px;

    grid-row: 7;
    grid-column: span 2;

    margin: 10px;
    max-width: 440px;
}

.editButton:hover {
    background: #4669e9be;
    cursor: pointer;

}

.editText {

    width: 104px;
    height: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #FFFFFF;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.empty {
    height: 10px;
    position: absolute;
    top: 1480px;
    padding: 0px 10px;
    gap: 8px;
}

.foterWrap {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.warp {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 8px;
    min-height: 450px;

    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 3;
    grid-column: 2;

    margin: 10px;
}

.tableTitle {

    height: 23px;


    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    color: #252522;


}

.tableSelect {

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;



    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 150px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.dateForm {
    /* date */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 341px;
    height: 44px;

}

.decil {
    /* Rectangle 12 */

    width: 15px;
    height: 1px;

    background: #252522;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.startDate {
    /* Frame 13 */



    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 155px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;

}

.endDate {
    /* Frame 30 */



    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 155px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;

}


.divTable {
    margin: 0px 10px;

}

.tableTop {
    /* top */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 32px;
    gap: 44px;

    width: 700px;
    height: 36px;

    background: #F5F4F3;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.tableBottom {
    /* list */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 700px;
    height: 210px;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

}

.paginate {
    /* Frame 68 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;

    width: 357px;
    height: 44px;

}

.paginateText {

    height: 18px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(43, 47, 62, 0.5);

    flex: none;
    order: 0;
    flex-grow: 0;

}

.paginateSelect {
    /* Frame 66 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 161px;
    height: 44px;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.paginateSelectText {

    width: 63px;
    height: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.select {
    /* Frame 62 */



    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 86px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.paginateInput {
    /* Frame 67 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100px;
    height: 44px;

    flex: none;
    order: 2;
    flex-grow: 0;

}

.arrowLeft {
    /* 16px / Arrow left */

    width: 16px;
    height: 16px;
    display: flex;
    flex: none;
    order: 0;
    flex-grow: 0;

}

.arrowRight {
    /* 16px / Arrow left */

    width: 16px;
    height: 16px;
    display: flex;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.page {
    /* Frame 62 */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 44px;
    height: 44px;

    background: rgba(149, 151, 159, 0.1);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.devicePerPage {
    /* Frame 66 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 161px;
    height: 44px;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.devicePerPageText {

    width: 63px;
    height: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 0;
    flex-grow: 0;
}

.inputPerPage {
    /* Frame 62 */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;



    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap: 12px;

    width: 86px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;

}

.infoWarp {
    grid-row: span 3;
    grid-column: 1;
}

.graphContainer {

    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 4;
    grid-column: 2;

    margin: 10px;

}

.graphTitle {

    height: 23px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    color: #252522;

    margin: 10px;


}

.graphSelect {
    /* Frame 62 */

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;


    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 155px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}


.graphDateForm {
    /* date */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 347px;
    height: 44px;


}

.lastData {
    /* Frame 71 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 152px;
    height: 18px;
}

.lastDataText {

    width: 136px;
    height: 18px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.currentData {
    /* Frame 70 */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 146px;
    height: 18px;



}

.currentDataText {

    width: 130px;
    height: 18px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.actionTable {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 14px 16px;
}

.downland {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 188px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

}

.downland:hover {
    background: #466AE91A;
    cursor: pointer;

}


.downlandText {

    height: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 1;
    flex-grow: 0;

}

.tableText {

    width: auto;
    height: 18px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 0;
    flex-grow: 0;

}


.table {
    border-collapse: separate;
    border-spacing: 0 6px;
    width: 100%;
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
}

.table thead tr {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #E1E0DF;

}


.table thead th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table thead th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table th {
    border-bottom: 2px solid #ddd;
}

.table tbody tr td {
    border-bottom: 1px solid #ddd;
}

.tableBodyText {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;

    color: #252522;

    flex: none;
    order: 2;
    flex-grow: 1;

}

.tableDate {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #252522;

    flex: none;
    order: 0;
    flex-grow: 0;

}

.tableTime {

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(37, 37, 34, 0.6);

    flex: none;
    order: 1;
    flex-grow: 0;

}

.datetime {
    display: flex;
    gap: 8px
}

.chart {

    padding-left: 20px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    margin: 20px 10px;
}

.graphActions {
    display: flex;
    margin: 10px;
}



.editContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.grid {

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 30px;
    flex: 1;

}

.popup {
    z-index: 1000;
    position: absolute;

}

.popupContent {

    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 8px;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.downlandButton {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 188px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
}

.downlandButton:hover {
    background: #466AE91A;
    cursor: pointer;
}

@media (min-width: 1024.10px) and (max-width: 1280.08px) {
    .buttonsGroup {
        grid-column: span 2;
    }

    .forwardback {
        grid-column: 1;
    }

    .title {
        grid-column: span 3;
    }

    .infoWarp {
        grid-row: span 3;
        grid-column: 1;
    }

    .warp {
        grid-row: 3;
        grid-column: span 2 / 4;
        min-width: 745px;
        max-width: 745px;
    }

    .graphContainer {
        grid-row: 4;
        grid-column: span 2 / 4;
    }
}


@media (min-width: 992px) and (max-width: 1024.08px) {
    .buttonsGroup {
        grid-column: span 2;
    }

    .forwardback {
        grid-column: span 2;
    }

    .infoWarp {
        grid-row: 3;
        grid-column: unset;
    }

    .warp {
        grid-row: 4;
        grid-column: unset;
        min-width: 745px;
        max-width: 745px;
    }

    .graphContainer {
        grid-row: 5;
        grid-column: unset;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .buttonsGroup {
        grid-column: span 2;
    }

    .forwardback {
        grid-column: span 2;
    }

    .infoWarp {
        grid-row: 3;
        grid-column: unset;
    }

    .warp {
        grid-row: 4;
        grid-column: unset;
        min-width: 745px;
        max-width: 745px;
    }

    .graphContainer {
        grid-row: 5;
        grid-column: unset;
    }
}

@media (min-width: 576px) and (max-width: 768.98px) {
    .buttonsGroup {
        grid-column: span 2;
    }

    .forwardback {
        grid-column: span 2;
    }

    .infoWarp {
        grid-row: 3;
        grid-column: unset;
    }

    .warp {
        grid-row: 4;
        grid-column: unset;
        min-width: 745px;
        max-width: 745px;
    }

    .graphContainer {
        grid-row: 5;
        grid-column: unset;
    }
}

@media (max-width: 575.98px) {
    .buttonsGroup {
        grid-column: 1;
        grid-row: 2;
        justify-self: auto;
    }

    .title {
        grid-row: 3;
        margin: 10px;
        max-width: 425px;
        height: 70px;
    }

    .forwardback {
        grid-column: span 2;
    }

    .infoWarp {
        grid-row: 4;
        grid-column: unset;

        max-width: 425px;
    }

    .warp {
        grid-row: 5;
        grid-column: unset;
        min-width: 745px;
        max-width: 745px;
    }

    .graphContainer {
        grid-row: 6;
        grid-column: unset;
    }
}