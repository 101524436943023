.main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
}

.searchFrame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 35vw;
    height: 44px;
    margin: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;
}


.search {
    border: none;
    outline: none;
    width: 95%;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.searchText {
    width: 223px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(37, 37, 34, 0.6);
    flex: none;
    order: 1;
    flex-grow: 0;
}

.actionFrame {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    margin: 10px;
    width: 609px;
    height: 44px;
    justify-self: right;
}



.devImport {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 171px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    transition: background-color 0.3s ease;
}

.devImport:hover {
    background: #466AE91A;
}

.devImportText {
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame13 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 151px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    transition: background-color 0.3s ease;
}


.frame13:hover {
    background: #466AE91A;
}


.frame13Text {
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame14 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 105px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    transition: background-color 0.3s ease;
}

.frame14:hover {
    background: #466AE91A;
}


.frame14logo {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame14Text {
    width: 25px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.frame15 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    width: 146px;
    height: 44px;
    background: #466AE9;
    border-radius: 8px;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
    transition: background-color 0.3s ease;
}

.frame15:hover {
    background: #4669e9be;
}


.frame15logo {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame15Text {
    width: 66px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.addressFrame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    margin: 0 10px 0 10px;
    position: relative;
    width: 201px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.addressFrame:hover {
    background: #466AE91A;
}

.frame75 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 141px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame75logo {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}


.frame75Text {
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #252522;
    flex: none;
    order: 1;
    flex-grow: 0;
}


.arrowDown {
    width: 16px;
    height: 16px;
    display: flex;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.arrowDown img {
    width: 16px;
}

.frame35 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 44px;
    width: 100%;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.frame48 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: auto;
    height: 24px;
    white-space: nowrap;
    flex: 1;
    order: 0;
    flex-grow: 0;
}

.frame48Text {
    width: auto;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tableArrowDown {
    width: 24px;
    height: 24px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.setting {
    width: 24px;
    height: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.tableList {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: auto;
    overflow-y: auto;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.frame33 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 76px 12px 32px;
    gap: 44px;
    width: 100%;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;
    flex: 1;
}

.status {
    padding: 0px;
    gap: 8px;
    width: 69px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.statusElipse {
    display: flex;
    width: 8px;
    height: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
}


.lock {
    width: 16px;
    height: 16px;
    display: flex;
    flex: none;
    order: 1;
    flex-grow: 0;
}


.redText {
    width: 49px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #E94650;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.blackText {
    width: 49;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.blueText {
    width: 49px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: #466AE9;
    flex: none;
    order: 1;
    flex-grow: 0;
}


.addressText {
    width: auto;
    height: 18px;
    min-width: 120px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.tableListText {
    width: auto;
    height: 18px;
    min-width: 120px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.paginate {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    margin-right: 20px;
    position: relative;
    top: 10px;
    width: 370px;
    height: 44px;
    float: right;
}

.quantityDevice {
    width: auto;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(43, 47, 62, 0.5);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.devicePerPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 161px;
    height: 44px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.devicePerPageText {
    width: 63px;
    height: 18px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.inputPerPage {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap: 12px;
    width: 86px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;
    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.currentPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100px;
    height: 44px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.arrowLeft {
    width: 16px;
    height: 16px;
    display: flex;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.arrowRight {
    width: 16px;
    height: 16px;
    display: flex;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 44px;
    height: 44px;
    background: rgba(149, 151, 159, 0.1);
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.tableContainer {
    padding: 0;
    grid-column: span 2;
    width: 100%;
}

.deviceTable {
    border-collapse: separate;
    border-spacing: 0 5px;
    padding: 10px;
    max-width: 2340px;
    width: 100%;
}

.deviceTable th,
.deviceTable td {
    padding: 10px;
    text-align: left;
}

.deviceTable thead tr {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #E1E0DF;

}


.deviceTable thead th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.deviceTable thead th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.deviceTable th {
    border-bottom: 2px solid #ddd;
}

.deviceTable tbody tr {
    background-color: #fff;
    border-radius: 10px;
}

.deviceTable tbody tr td {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.deviceTable tbody tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.deviceTable tbody tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.warp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.addressPopup {
    position: absolute;
    top: 100%;
}

.address {
    background-color: rgb(255, 255, 255);
    color: rgb(77, 79, 92);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    min-width: 400px;
    max-height: 400px;
    overflow: hidden auto;
    padding: 8px;
    margin-top: 8px;
}

.addressInput {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    padding: 0px 0px 8px;
    margin: 0px;
    border: 0px;
    vertical-align: top;
    width: 100%;
}

.addressSearch {
    border: none;
    outline: none;
    border-bottom-style: groove;
    padding: inherit;
}

.addressInput img {
    position: absolute;
    left: 93%;
    top: 2px;
}

.MuiBarElement-root {
    fill: #252522;
}



.addressWarp {
    display: flex;
    align-items: center;
    grid-row: 2;
    /* grid-column: span 2; */
}

.chosedAddr {
    display: flex;
    cursor: pointer;
}

.chosedAddrText {
    padding: 0px 5px;
    color: #000;
    transition: color 0.3s; 
}

.chosedAddrImg {
    padding: 0px 5px;
    fill: #000;
    transition: fill 0.3s;
}

.chosedAddr:hover .chosedAddrText {
    filter: brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(1863%) hue-rotate(206deg) brightness(95%) contrast(91%);
}

.chosedAddr:hover .chosedAddrImg img {
    filter: brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(1863%) hue-rotate(206deg) brightness(95%) contrast(91%);
}

@media (min-width: 1600px) {
    .tableList {
        max-height: 650px;
    }

    .tableListText {
        font-size: 16px;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    .tableListText {
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

    .tableListText {
        font-size: 14px;
    }
}

@media (max-width: 575.98px) {
    .searchFrame {
        width: 95%;
        grid-column: span 2;
        position: relative;
    }

    .actionFrame {
        width: 100%;
        top: 120px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        
    }

    .addressFrame {
        top: 0;
        padding: 0px 10px;
        width: 183px;
        position: relative;
        margin-top: 8px;
        grid-column: 1;
        grid-row: 2;
        
    }

    .menuPopup {
        position: absolute;
        right: 0;
        top: 100%;
        width: max-content;
    }

    .menuPopupWarp {
        display: flex;
        gap: 4px;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        color: rgb(77, 79, 92);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        min-width: 188px;
        max-height: 400px;
        overflow: hidden auto;
        padding: 8px;
        margin-top: 8px;
    }

    .podval {
        width: 100%;
        height: 50px;
        margin-top: 80px;
    }

    .menuPopupWarp div {
        width: 100%;
    }

    .actionMobileText {
        height: 18px;
        font-family: 'IBM Plex Sans' sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        color: #252522;
        flex: none;
        order: 1;
        flex-grow: 0;
        
    }

    .warpFrame {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        width: 68px;
        height: 24px;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .address {
        min-width: 280px;
    }

    .butAct {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .actionMobileFrame {
        display: flex;
        flex-direction: row;
        align-items: center;
        top: 0;
        padding: 0px 12px;
        width: 115px;
        position: relative;
        border: 1px solid rgba(43, 47, 62, 0.15);
        border-radius: 8px;
        transition: background-color 0.3s ease;
        height: 44px;
        background: #FFFFFF;
        grid-column: 2;
        grid-row: 2;
        justify-self: right;
        margin: 10px 10px 0 0;
    }

    .deviceTable {
        width: 100%;
        margin-top: 8px;
        gap: 8px;

    }

    .tableListText {
        font-size: 14px;
    }

    .address {
        font-size: 14px;
    }

    .deviceTable,
    .deviceTable thead,
    .deviceTable tbody,
    .deviceTable th,
    .deviceTable tr {
        display: block;
        width: 100%;
    }

    .deviceTable tbody tr td {
        border-top: none;
        border-bottom: none;
    }

    .deviceTable thead {
        display: none;
    }

    .deviceTable tbody tr {
        margin-bottom: 8px;
    }

    .deviceTable tr {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin-bottom: 15px;
    }

    .deviceTable td {
        border: none;
        padding: 5px 0;
        font-size: 16px;
        text-align: left;
    }

    .deviceTable td.status {
        grid-column: span 1;
        min-height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;

    }

    .deviceTable td.blueText {
        grid-column: span 2;
        width: 100%;
        text-transform: none;
        text-align: left;
        order: 2;
        min-height: 35px;
        display: flex;
        flex-direction: column;

    }

    .deviceTable td.redText {
        grid-column: span 2;
        width: 100%;
        text-transform: none;
        text-align: left;
        order: 2;
        min-height: 35px;
        display: flex;
        flex-direction: column;
    }

    .deviceTable td.blackText {
        grid-column: span 2;
        width: 100%;
        text-transform: none;
        text-align: left;
        order: 2;
        min-height: 35px;
        display: flex;
        flex-direction: column;
    }

    .deviceTable td.details {
        grid-column: span 1;
        display: flex;
        justify-content: flex-end;
    }

    .deviceTable td.addressText {
        grid-column: span 2;
        min-height: 35px;
        order: 1;
    }

    .deviceTable td.tableListText {
        grid-column: span 1;
        order: 2;
        min-height: fit-content;
        max-width: 50%;
    }

    .deviceTable td:before {
        content: attr(data-label);
        font-weight: bold;
        color: #000000;
        float: left;
        font-size: 13px;
    }

    .paginate {
        width: auto;
        gap: 8px;
        top: 0;
        padding: 10px;
    }

    .frame75logo {
        width: 20px;
    }

    .frame75 {
        gap: 6px;
    }
}