.main {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    top: 90px;
    position: absolute;
    width: 560px;
    height: 44px;
    left: 650px;
}

.searchFrame {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    margin: 10px;
    width: 25vw;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;

    grid-column: 1;
    grid-row: 1;

}


.search {
    /* search */
    border: none;
    outline: none;
    width: 95%;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.searchText {
    /* text */

    width: 223px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(37, 37, 34, 0.6);


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.frame16 {
    /* Frame 16 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: auto;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    transition: background-color 0.3s ease;

}

.frame16:hover {
    background: #466AE91A;
}

.frame16Text {
    /* text */

    width: 65px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.frame13 {
    /* Frame 13 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 151px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    transition: background-color 0.3s ease;

}

.frame13:hover {
    background: #466AE91A;
}


.frame13Text {
    /* text */

    width: 103px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.frame14 {
    /* Frame 14 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 105px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;

    transition: background-color 0.3s ease;

}

.frame14:hover {
    background: #466AE91A;
}


.frame14logo {
    /* 24px / History */

    width: 24px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame14Text {

    /* text */

    width: 25px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.frame15 {

    /* Frame 15 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 120px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;


    /* Inside auto layout */
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;

    transition: background-color 0.3s ease;

}

.frame15:hover {
    background: #466AE91A;
}

.frame15Text {
    /* text */

    width: 55px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.wrapper {
    grid-column: 2;
    grid-row: 1;
    display: contents;
}

.addressFrame {

    box-sizing: border-box;

    margin: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;
    position: relative;

    width: 201px;
    height: 44px;    

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;

    transition: background-color 0.3s ease;

    grid-column: 1;
    grid-row: 2;

}

.frame62:hover {
    background: #466AE91A;
}

.frame75 {
    /* Frame 75 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 141px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.frame75logo {
    /* 24px / Address */

    width: 24px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}


.frame75Text {
    /* text */

    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.warps {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 60px;
    flex: none;
    order: 0;
    flex-grow: 0;
}


.frame48Text {
    width: fit-content;
    word-break: auto-phrase;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tableArrowDown {
    width: 24px;
    height: 24px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;
    flex: none;
    order: 1;
    flex-grow: 0;
}


.arrowDown {
    /* 16px / Arrow down */

    width: 16px;
    height: 16px;

    display: flex;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.arrowDown img {
    /* 16px / Arrow down */

    width: 16px;
}

.addressPopup {
    position: absolute;
    top: 100%;
    z-index: 10;
}

.address {
    background-color: rgb(255, 255, 255);
    color: rgb(77, 79, 92);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    min-width: 400px;
    max-height: 400px;
    overflow: hidden auto;
    padding: 8px;
    margin-top: 8px;
}

.addressInput {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    padding: 0px 0px 8px;
    margin: 0px;
    border: 0px;
    vertical-align: top;
    width: 100%;
}

.addressSearch {
    border: none;
    outline: none;
    border-bottom-style: groove;
    padding: inherit;
}

.addressInput img {
    position: absolute;
    left: 93%;
    top: 2px;
}

.type {
    /* text */

    position: absolute;
    height: 23px;
    left: 24px;
    top: 35px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;

    color: #252522;


}



/* @media (min-width: 1600px) {

}

@media (min-width: 1200px) and (max-width: 1599.98px) {

} */

@media (min-width: 992px) and (max-width: 1199.98px) {
    .searchFrame {
        padding: 0 24px 0 10px;
        
    }
}

@media (max-width: 991.98px) {
    .searchFrame {
        padding: 0 24px 0 10px;
        width: 35vw;
    }
}


@media (min-width: 1600px) {
    .paginate {
        width: 100%;
        left: 1200px;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    .paginate {
        width: 100%;
        top: auto;
        left: 775px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .paginate {
        width: 100%;
    }
}

.paginate {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    height: 44px;
    grid-column: 2;
    justify-content: right;
    order: 4;

}

.paginateText {

    width: auto;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(43, 47, 62, 0.5);
    flex: none;
    order: 0;
    flex-grow: 0;

}

.paginateSelect {
    /* Frame 66 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 161px;
    height: 44px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.paginateSelectText {
    /* text */

    width: 63px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.select {
    /* Frame 62 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 86px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.paginateInput {
    /* Frame 67 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100px;
    height: 44px;


    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

}

.arrowLeft {
    /* 16px / Arrow left */

    width: 16px;
    height: 16px;
    display: flex;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.arrowRight {
    /* 16px / Arrow left */

    width: 16px;
    height: 16px;
    display: flex;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.page {
    /* Frame 62 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 44px;
    height: 44px;

    background: rgba(149, 151, 159, 0.1);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.devicePerPage {
    /* Frame 66 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 161px;
    height: 44px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.devicePerPageText {
    /* text */

    width: 63px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.inputPerPage {
    /* Frame 62 */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap: 12px;

    width: 86px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}


.tableContainer {
    background-color: #FFFFFF;
    border-radius: 8px;
    grid-column: span 2;
    grid-row: 3;
    margin: 10px;
}

.warp {
    background: #FFFFFF;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;

}




.tableSelect {

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    width: 8vw;
    height: 44px;
    left: 165px;
    top: 24px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.dateForm {
    /* date */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 376px;
    height: 44px;
    left: 380px;
    top: 24px;

}

.decil {
    /* Rectangle 12 */

    width: 20px;
    height: 1px;

    background: #252522;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.startDate {
    /* Frame 13 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 155px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.endDate {
    /* Frame 30 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 155px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

}

.types {
    /* text */
    margin-right: 10px;
    height: 23px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;

    color: #252522;
}


.typeFrame {
    display: flex;
    order: 1;
    align-items: center;
    margin-left: 10px;
    padding: 10px;
}

.table {
    padding: 10px;
    grid-column: span 2;
    order: 3;
}

.table table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
        minmax(150px, 2.5fr) minmax(140px, 6.33fr) minmax(100px, 1.67fr) minmax(125px, 1.6fr) minmax(125px, 1.6fr) minmax(150px, 3.33fr) minmax(70px, 1.3fr);
}

.table thead,
.table tbody,
.table tr {
    display: contents;
}

.table tbody {
    max-height: 600px;
    overflow: auto;
}


.table td {
    align-content: center;
}

.table thead  th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table thead th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table tbody tr td {
    border-bottom: 1px solid #ddd;
}


.tableBodyText {
    /* text */

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: left;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 1;

}

.tableDate {

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.tableTime {
    /* text */

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(37, 37, 34, 0.6);
    flex: none;
    order: 1;
    flex-grow: 0;

}

.datetime {
    display: flex;
    gap: 8px
}


.table-container {
    display: flex;
    flex-direction: column;
}

.tableHeader {
    overflow: hidden;
    
}

.tableBody {
    display: block;
    height: auto; /* Set the desired height */
    overflow-y: auto;
}

.tableBody table {
    width: 100%;
    border-collapse: collapse;
}

.tableHeader table,
.tableBody table {
    table-layout: fixed;
    min-height: 80px;
}

.tableHeader th,
.tableBody td {
    padding: 10px;
    text-align: left;
}

.tableHeader th {
    background-color: #E1E0DF;
    position: sticky;
    top: 0;
}

.addressWarp {
    display: flex;
    align-items: center;
    grid-row: 2;
    grid-column: span 2;
}

.chosedAddr {
    display: flex;
    cursor: pointer;
}

.chosedAddrText {
    padding: 0px 5px;
    color: #000;
    transition: color 0.3s; 
}

.chosedAddrImg {
    padding: 0px 5px;
    fill: #000;
    transition: fill 0.3s;
}

.chosedAddr:hover .chosedAddrText {
    filter: brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(1863%) hue-rotate(206deg) brightness(95%) contrast(91%);
}

.chosedAddr:hover .chosedAddrImg img {
    filter: brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(1863%) hue-rotate(206deg) brightness(95%) contrast(91%);
}

@media (min-width: 1600px) {
    
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    
}

@media (min-width: 768) and (max-width: 991.98px) {
    
}

@media (min-width: 576px) and (max-width: 768.98px){

}

@media (max-width: 575.98px) { 

}