.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    background: #F5F4F3;
    border-bottom: 1px solid rgba(37, 37, 34, 0.15);
    z-index: 2;
}

.mobileMenu,
.mobileUser {
    width: 40px;
    height: 40px;
}

.mobileMenu img,
.mobileUser img {
    width: 100%;
}


.logo {
    margin: 0 30px;
    width: 103px;
    height: 30px;
    background: url(granlogo.svg);
    flex: none;
    order: 0;
    flex-grow: 0;

}

.label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2vw;
    height: 28px;
    border-radius: 8px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.user {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1vw;
    height: 24px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.user span {
    max-width: 250px;
    height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 14vw;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.userIcon {
    width: 24px;
    height: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.grid1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: auto;
    height: 28px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.grid1 span {
    width: auto;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.grid2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: auto;
    height: 28px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.grid2 span {
    width: auto;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.grid3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: auto;
    height: 28px;
    flex: none;
    order: 2;
    flex-grow: 0;
}

.grid3 span {
    width: auto;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.grid4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: auto;
    height: 28px;
    flex: none;
    order: 3;
    flex-grow: 0;
}

.grid4 span {
    width: auto;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #252522;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gridItem1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    width: 64px;
    height: 28px;
    background: rgba(70, 106, 233, 0.2);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gridItem1 span {
    width: auto;
    height: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #466AE9;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gridItem2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    width: 64px;
    height: 28px;
    background: rgba(63, 194, 163, 0.15);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gridItem2 span {
    height: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3FC2A3;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gridItem3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    width: 64px;
    height: 28px;
    background: rgba(233, 70, 80, 0.15);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gridItem3 span {
    height: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #E94650;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.gridItem4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    width: 64px;
    height: 28px;
    background: rgba(149, 151, 159, 0.15);
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.gridItem4 span {
    height: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #95979F;
    flex: none;
    order: 0;
    flex-grow: 0;
}

@media (min-width: 1600px) {
    .header {
        width: 100%;
        height: auto;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    .header {
        width: 100%;
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .header {
        width: 100%;
        padding: 10px;
        position: relative;
    }

    .logo {
        margin: 0 15px;
    }
}

@media (max-width: 575.98px) {
    .header {
        width: 100%;
        padding: 10px;
        position: relative;
    }

    .label {
        display: none !important;
    }

    .user {
        display: none !important;
    }


}