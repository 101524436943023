.sideBarFrame {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 12px 12px;
    width: 220px;
    background: #F5F4F3;
    border-right: 1px solid rgba(37, 37, 34, 0.15);
    z-index: 1;
}

.sideBarBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 196px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.sideBarGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;

    margin: 0 auto;
    width: 196px;
    height: 172px;
    flex: none;
    order: 0;
    flex-grow: 0;

}

.item1, .item2, .item3, .item4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 0px 14px 20px;
    gap: 12px;
    width: 196px;
    height: 52px;
    border-radius: 10px;
    cursor: pointer; /* курсор меняется на указатель */

    flex: none;
    order: 0;
    flex-grow: 0;

    transition: background-color 0.3s ease;

}

.item1:not(.active):hover,
.item2:not(.active):hover,
.item3:not(.active):hover,
.item4:not(.active):hover {
    background: #466AE91A;
}

.locationOn, .vibration, .article, .setting {
    width: 24px;
    height: 24px;

    flex: none;
    order: 0;
    flex-grow: 0;
}


.locationBox {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 20px;
    top: 14px;

    background: #D9D9D9;
}

.locationText, .vibrationText, .articleText, .settingText {
    width: 46px;
    height: 24px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #252522;
    flex: none;
    order: 1;
    flex-grow: 0;
}



@media (min-width: 1600px) {
    .sideBarFrame {
        width: 220px;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    .sideBarFrame {
        width: 220px;
    }
}

/* @media (min-width: 768px) and (max-width: 991.98px) {
    .sideBarFrame {
        width: 220px;
    }
} */

@media (max-width: 991.98px) {
    .sideBarFrame {
        width: 50%;
        padding: 0;
        background: none;
        border-right: none;
        display: inline;
        top: 61px;
        position: absolute;
        margin-left: -10px;
    }
    .sideBarBox {
        padding: 5px;
        background: #F5F4F3;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .sideBarGroup, .item1, .item2, .item3, .item4 {
        width: 100%;
    }

    .item1, .item2, .item3, .item4 {
        padding: 14px 30px 14px 20px;
    }

    .item4 {
        margin-top: 8px;
    }

}

.vibrationBox {
    /* Bounding box */

    position: absolute;
    width: 24px;
    height: 24px;
    left: 20px;
    top: 14px;

    background: #D9D9D9;

}

.arrowBack {
    /* 16px / Arrow back */

    width: 16px;
    height: 16px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.arrowBox {
    /* Bounding box */

    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    background: #D9D9D9;

}

.arrowBackIos {
    /* arrow_back_ios */

    position: absolute;
    width: 7.85px;
    left: calc(50% - 7.85px/2 - 0.07px);
    top: 8.33%;
    bottom: 8.33%;

    background: #466AE9;

}

.active {
    background-color: #466AE9; /* новый цвет для активного элемента */
    & .locationText, .vibrationText, .articleText, .settingText {
        color: #FFFFFF; 
    }

}

.activeIcon {
    filter: brightness(0) invert(1);
}