.main {
    display: flex;
    margin: 10px;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    position: absolute;
    width: 200px;

    background: #FFFFFF;
    border-radius: 8px;

}

.forms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 10px;
    width: 100%;

    background: #FFFFFF;
    border-radius: 8px;

}

.forms form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 8px;
}

form h4 {
    grid-column: 3 span;
}


form button {
    grid-column: 1;
    grid-row: 0;
    margin-top: 10px !important;
}


