.line {
    display: flex;
}

.line a {
    padding: 1px;
}

.caret {
    width: 24px;
}

.caretDown {
    width: 24px;
}

.nested {
    display: none;
}

.active {
    display: block;
}


.treeline,
.treeline ul,
.treeline li li:before {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  list-style: none;
}

.treeline ul {
  margin: 0 0 0 15px;
}









