.period {
    /* text */

    height: 23px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;

    margin-right: 10px;

    color: #252522;


}

.tableSelect {

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 12px;

    height: 44px;
    min-width: 11vw;
    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
}

.dateForm {
    /* date */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    margin-right: 10px;

    height: 44px;


}

.decil {
    /* Rectangle 12 */

    width: 20px;
    height: 1px;

    background: #252522;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.periodFrame {
    display: flex;
    order: 2;
    align-items: center;
    padding: 10px;
}

.startDate {
    /* Frame 13 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 14vw;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.endDate {
    /* Frame 30 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;

    width: 14vw;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

}