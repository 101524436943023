.successMessage {
    padding: 10px;
    background-color: lightgreen;
}

.errorMessage {
    padding: 10px;
    background-color: lightcoral;
}

.actEditButton {
    margin: 0 5px 0 5px;
    color: #466AE9;
    cursor: pointer;
}

.actDelButton {
    margin: 0 5px 0 5px;
    color: #E94650;
    cursor: pointer;
}

.action {
    display: flex;
}


.container {
    display: flex;
    /* height: calc(100vh - 70px); */
}

.menu {
    flex: 0 0 auto;
    width: 25%;
    margin-top: 1%;
    padding-right: 0;
    background-color: #f8f8f8;
}

.menu div {
    padding: 1%;
    display: flex;
    justify-content: center;
    transition: background-color 0.3s; /* Плавный переход цвета фона */
}

.menuItem {
    cursor: pointer;
  }

.forms.darken {
    background-color: rgba(0, 0, 0, 0.1);
}  

.active {
    background-color: rgba(0, 0, 0, 0.08);
}

.menu div:hover {
    background-color: rgba(0, 0, 0, 0.08); /* Затемнение при наведении */
  }

.menu span {
    /* background-color: #e3e3e3; */
    padding: 3%;
    text-align: center;
    font-size: 18px;
}

.forms {
    flex: 0 0 auto;
    margin-top: 1%;
    padding: 1%;
    display: flex;
    justify-content: center; /* Горизонтальное центрирование */

}

.btn {
    min-width: 100%;
    padding: 23px 75px;
    background: #c1cfff;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 23px;
    font-variant: small-caps;
    color: #000000;

    &:hover {
        background: #3d74dc;
    }

    &:active {
        background: #0C52D5;
    }

    &:disabled {
        background: #5C5C5C;
        box-shadow: none;
    }
}

.formAdd {
    padding: 0% 10% 10% 10%;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    color: #000000;
}


.form {
    width: 70%;
    label {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        font-variant: small-caps;
    }

    input {
        width: 100%;
        padding: 2%;
        background-color: transparent;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;

        &:-webkit-autofill {
            -webkit-text-fill-color: #000000;
            transition: all 5000s ease-in-out;
        }

        &:focus-visible {
            outline: none;
        }
    }

    select {
        width: 100%;
        padding: 2%;
        background-color: transparent;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;

        &:-webkit-autofill {
            -webkit-text-fill-color: #000000;
            transition: all 5000s ease-in-out;
        }

        &:focus-visible {
            outline: none;
        }
    }

}

.inputField {
    display: flex;
    position: relative;
}

.formTittle {
    color: #000000;
    font-weight: 600;
    font-size: 24px;
    align-self: center;
    line-height: 47px;
    text-transform: capitalize;
    padding-bottom: 35px;
}

input[type="checkbox"] {
    height: 20px; 
    width: 20px;
    margin-left: 10px;
    margin-bottom: 0;
  }

.inCheck {
    margin-bottom: 10px;
}  

.error {
    margin-top: -15px;
    color: #F13A3A;
    font-size: 15px;    
    padding-bottom: 15px;
}


.main {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(500px, auto);
}

.sidebar {
    padding: 1% 1% 1% 5%;
    grid-column: 1;
    background-color: rgb(232, 232, 232);
    display: flex;
    flex-direction: column;
    text-align: left;
}

.content {
    padding: 1%;
    grid-column: 2 / 6;
}

.itemMenu{
    padding: 1%;
    font-size: 20px;
    margin: 1%;
}

.dropItem {
    padding: 1% 1% 1% 5%;
}

