.container {
    display: flex;
}

.importGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.add {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    margin: 10px;
    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 2;
    grid-column: 1;
}

.import {
    flex: 0 0 auto;
    width: 48%;
    margin: 1%;
    padding: 1%;
}

.importBox {
    flex: 0 0 auto;
    margin: 1%;
    padding: 1%;

}

.example {
    margin-left: 10px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.box {
    display: flex;
    flex-direction: column;
    flex: auto;
}


.btn {
    min-width: 90%;
    padding: 23px 75px;
    background: #c1cfff;
    border-radius: 24px;
    font-weight: 600;
    font-size: 20px !important;
    line-height: 23px;
    font-variant: small-caps;
    color: #000000;

    &:hover {
        background: #3d74dc;
    }

    &:active {
        background: #0C52D5;
    }

    &:disabled {
        background: #5C5C5C;
        box-shadow: none;
    }
}

.formAdd {
    padding: 0% 10% 0 10%;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    color: #000000;
}

.formAddLora {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    color: #000000;


}

.formAddLora form {
    padding: 0% 10% 5% 10%;
}

.bord {
    border-bottom: 1px solid black;

}

.titleContainer {
    display: flex;
    justify-content: space-between;
}

.downland {
    /* Frame 16 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 188px;
    height: 44px;


    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

}

.downland:hover {
    background: #466AE91A;
}


.downlandText {

    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.inputQr {
    display: flex;
}

.qrDiv {
    display: flex;
    align-items: center;
    padding: 5px;
}

.form {
    label {
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        font-variant: small-caps;
    }

    input {
        width: 90%;
        background: #76B9FF;
        padding: 2%;
        background-color: transparent;
        margin-bottom: 35px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;

        &:-webkit-autofill {
            -webkit-text-fill-color: #000000;
            transition: all 5000s ease-in-out;
        }

        &:focus-visible {
            outline: none;
        }
    }

    select {
        width: 100%;
        padding: 2%;
        background-color: transparent;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;

        &:-webkit-autofill {
            -webkit-text-fill-color: #000000;
            transition: all 5000s ease-in-out;
        }

        &:focus-visible {
            outline: none;
        }
    }

    a {
        font-size: 15px;
    }


}

.inputField {
    margin: 16px;
    display: flex;
    position: relative;
}

.img {
    width: 40px;
    height: 40px;
}

.formTittle {
    width: auto;
    /* height: 23px; */

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    color: #252522;
    margin: 10px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}



.tabWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;

    margin: 10px;

    background: #FFFFFF;
    border-radius: 8px;

    grid-row: 2;
    grid-column: 2;

}


.tabContent {
    flex: 1 1 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #000000;
}

.forwardback {
    /* Frame 14 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    width: 123px;
    height: 44px;

    background: rgba(70, 106, 233, 0.1);
    border: 1px solid rgba(70, 106, 233, 0.1);
    border-radius: 8px;

    grid-row: 1;
    grid-column: 1;

}

.forwardback:hover {
    background: #4669e97e;
}

.backIcon {

    /* 24px / Back */

    width: 24px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


}

.forwardbackText {
    /* text */

    width: 43px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #466AE9;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

@media (min-width: 1600px) {

    .forwardback {
        margin: 10px;
    }

    .tabWrap {
        margin: 10px;
    }

    .formAdd {
        width: 100%;
    }

}

@media (min-width: 1200px) and (max-width: 1599.98px) {

    .forwardback {
        margin: 10px;
    }

    .formAdd {
        width: 100%;
    }

}


@media (min-width: 992px) and (max-width: 1199.98px) {

    .add {
        width: auto;
        margin: 10px;
    }

    .forwardback {
        margin: 10px;
    }

    .formAdd {
        width: 100%;
    }

    .tabWrap {
        margin: 10px;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .forwardback {
        width: auto;
        grid-column: span 2;
    }

    .formAdd {
        width: 100%;
        padding: 0% 5% 0 5%;
    }

    .add {
        width: auto;
        margin: 10px;
        grid-column: span 2;
    }

    .tabWrap {
        margin: 10px;
        grid-row: 3;
        grid-column: span 2;
    }
}

@media (max-width: 575.98px) {
    .forwardback {
        width: auto;
        grid-column: span 2;
    }

    .formAdd {
        width: 100%;
        padding: 0% 5% 0% 5%;
    }

    .add {
        width: auto;
        margin: 10px;
        grid-column: span 2;
    }

    .tabWrap {
        margin: 10px;
        grid-row: 3;
        grid-column: span 2
    }
}