.sideBarFrame {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* padding: 0px 12px 12px; */
    width: 220px;
    background: #F5F4F3;
    border-right: 1px solid rgba(37, 37, 34, 0.15);
    z-index: 1;
}

.sideBarBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 12px;
    width: 100%;
}

.sideBarGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin: 0 auto;
    width: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.menuItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 10px 14px 10px;
    gap: 12px;
    width: 100%;
    height: 52px;
    border-radius: 10px;
    cursor: pointer;
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: background-color 0.3s ease;
}

.menuItem:not(.active):hover{
    background: #466AE91A;
}

.locationBox {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 20px;
    top: 14px;

    background: #D9D9D9;
}

.articleText {
    height: 24px;
    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #252522;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.backIcon {
    width: 24px;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.forwardbackText {
    width: 43px;
    height: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #466AE9;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.forwardback {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    height: 44px;
    width: 100%;
    background: rgba(70, 106, 233, 0.1);
    border: 1px solid rgba(70, 106, 233, 0.1);
    grid-row: 1;
    grid-column: 1;
}

.forwardback:hover {
    background: #4669e97e;
    cursor: pointer;
}

@media (min-width: 1600px) {
    .sideBarFrame {
        width: 220px;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    .sideBarFrame {
        width: 220px;
    }
}

/* @media (min-width: 768px) and (max-width: 991.98px) {
    .sideBarFrame {
        width: 220px;
    }
} */

@media (max-width: 991.98px) {
    .sideBarFrame {
        width: 50%;
        padding: 0;
        background: none;
        border-right: none;
        display: inline;
        top: 61px;
        position: absolute;
        margin-left: -10px;
    }
    .sideBarBox {
        padding: 5px;
        background: #F5F4F3;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }

    .sideBarGroup, .menuItem {
        width: 100%;
    }

    .menuItem{
        padding: 14px 30px 14px 20px;
    }


}

.vibrationBox {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 20px;
    top: 14px;
    background: #D9D9D9;
}

.arrowBack {
    width: 16px;
    height: 16px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.arrowBox {
    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #D9D9D9;
}

.arrowBackIos {
    position: absolute;
    width: 7.85px;
    left: calc(50% - 7.85px/2 - 0.07px);
    top: 8.33%;
    bottom: 8.33%;
    background: #466AE9;
}

.active {
    background-color: #466AE9; /* новый цвет для активного элемента */
    & .articleText {
        color: #FFFFFF; 
    }
}

.activeIcon {
    filter: brightness(0) invert(1);
}