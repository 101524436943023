/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* style.css */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  font-family: 'IBM Plex Sans' sans-serif !important;
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

li {
  list-style-type: none;
}


button {
  font-size: 14px !important;
}


@media (min-width: 992px) {

  .map-container.mapboxgl-map,
  .mapboxgl-canvas {
    position: relative !important;
    height: calc(100vh - 71px);
  }
}


@media (min-width: 576px) and (max-width: 991.98px) {

  .map-container.mapboxgl-map,
  .mapboxgl-canvas {
    position: relative !important;
    height: calc(100vh - 81px);
  }
}

@media (max-width: 575.98px) {
  .map-container.mapboxgl-map,
  .mapboxgl-canvas {
    position: relative !important;
    height: calc(100vh - 61px);
  }
}

.registration {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  max-width: 450px;
  max-height: 610px;
  margin-left: auto;
  margin-right: auto;
}

.registration input {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.registration input:last-child {
  margin-left: 10%;
  margin-right: 10%;
  background-color: #c1cfff;
  border: 0;
}

.registration form {
  display: flex;
  flex-direction: column;
}


.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
}



.map {
  position: absolute;
  width: 35%;
  margin-top: 1%;
  justify-content: flex-end;
}

.map div {
  background-color: white;
}


.tab-list-item {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 18px !important;
}

.tab-list-active {
  border-bottom: 2px solid blue;
  background-color: #e2e3e5;
}

.tabs {
  display: flex;
}