
.forwardback {
    /* Frame 14 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;

    position: absolute;
    width: 123px;
    height: 44px;
    left: 252px;
    top: 90px;

    background: rgba(70, 106, 233, 0.1);
    border: 1px solid rgba(70, 106, 233, 0.1);
    border-radius: 8px;

}

.forwardback:hover {
    background: #4669e97e;
}


.backIcon {

    /* 24px / Back */

    width: 24px;
    height: 24px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


}

.forwardbackText {
    /* text */

    width: 43px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #466AE9;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.tableContainer {
    padding: 0;
}

.deviceTable {
    border-collapse: separate;
    /* изменено на 'separate' для применения gaps */
    border-spacing: 0 5px;
    margin-top: 155px;
    /* добавление зазоров между строками */
}

.deviceTable th,
.deviceTable td {
    padding: 10px;
    text-align: left;
}

.deviceTable thead tr {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #E1E0DF;

}


.deviceTable thead th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.deviceTable thead th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.deviceTable th {
    border-bottom: 2px solid #ddd;
}

.deviceTable tbody tr {
    background-color: #fff;
    border-radius: 10px;
    /* скругление углов строк */

}

.deviceTable tbody tr td {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.deviceTable tbody tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
} 

.deviceTable tbody tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


@media (min-width: 1600px) {

    .frame12,
    .frame62,
    /* .deviceTable {
        margin-left: 252px;
    } */

    .frame28 {
        left: 1278px;
    }

    .deviceTable {
        width: 1634px;
    }

    .tableList {
        max-height: 650px;
    }

    .tableListText {
        font-size: 16px;
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {

    .frame12,
    .frame62,
    /* .deviceTable {
        margin-left: 252px;
    } */

    .frame28 {
        left: 830px;
    }

    .deviceTable {
        width: 1200px;
    }

    .tableListText {
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .frame12 .frame28 .frame62 .deviceTable {
        left: 252px;
    }

    .tableListText {
        font-size: 14px;
    }
}

@media (max-width: 575.98px) {
    .frame12 .frame28 .frame62 .deviceTable {
        left: 252px;
    }
    .tableListText {
        font-size: 14px;
    }
}

.warp {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    height: 16px;

    flex: none;
    order: 0;
    flex-grow: 0;
}


.frame48Text {
    /* text */

    width: auto;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.tableArrowDown {
    /* 24px / Arrow down */

    width: 24px;
    height: 24px;

    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: center;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.paginate {
    /* Frame 68 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    margin-right: 20px;

    position: relative;
    top: 10px;
    width: 370px;
    height: 44px;
    float: right;


}

.quantityDevice {
    /* text */

    width: auto;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: rgba(43, 47, 62, 0.5);


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.devicePerPage {
    /* Frame 66 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 161px;
    height: 44px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.devicePerPageText {
    /* text */

    width: 63px;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.inputPerPage {
    /* Frame 62 */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap: 12px;

    width: 86px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(37, 37, 34, 0.15);
    border-radius: 8px;

    background-image: url('arrowback.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.currentPage {
    /* Frame 67 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 100px;
    height: 44px;


    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

}

.arrowLeft {
    /* 16px / Arrow left */

    width: 16px;
    height: 16px;
    display: flex;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.arrowRight {
    /* 16px / Arrow left */

    width: 16px;
    height: 16px;
    display: flex;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.page {
    /* Frame 62 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 44px;
    height: 44px;

    background: rgba(149, 151, 159, 0.1);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}
