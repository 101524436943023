.main {
    width: 100%;
    height: 100vh;
    background: #F5F4F3;
}

.content {
    width: 100%;
    overflow: auto;
}

.container {
    display: flex;
}


.showSidebarButton {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 20px 1px 12px;

    position: absolute;
    width: 20px;
    left: 0px;
    top: 70px;
    bottom: 0px;

    background: #F5F4F3;
    border-right: 1px solid rgba(37, 37, 34, 0.15);
    cursor: pointer;

    z-index: 1;

    & img {
        margin-top: auto;
    }
}

.showSidebarButton:hover {
    background-color: #3651b2;

    & img {
        filter: brightness(0) invert(1);
    }
}




@media (min-width: 1600px) {

    /* * {
        font-size: calc(1em + 3px) !important;
    } */
    .main {
        width: 100%;
        background: #F5F4F3;
    }

    .container {
        width: 100%;
        height: calc(100vh - 71px);
    }
}

@media (min-width: 1200px) and (max-width: 1599.98px) {
    .main {
        width: 100%;
        top: auto;
        left: auto;
        background: #F5F4F3;
    }

    .container {
        width: 100%;
        height: calc(100vh - 71px);
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .main {
        width: 100%;
        top: auto;
        left: auto;
        background: #F5F4F3;
    }

    .container {
        width: 100%;
        height: calc(100vh - 71px);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .main {
        width: 100%;
        background: #F5F4F3;
    }

    .container {
        width: 100%;
        height: calc(100vh - 81px);
    }
}

@media (min-width: 576px) and (max-width: 768.98px){
    .main {
        width: 100%;
        background: #F5F4F3;
    }
    .container {
        width: 100%;
        height: calc(100vh - 81px);
    }
}

@media (max-width: 575.98px) {
    .main {
        width: 100%;
        background: #F5F4F3;
    }
    .container {
        width: 100%;
        height: calc(100vh - 61px);
    }
}



