.dropdown {
    position: 'absolute';
    left: 0;
    z-index: 999;
    background-color: '#fff';
    border: '1px solid #ccc';
    border-radius: '4px';
    box-shadow: '0px 4px 8px rgba(0, 0, 0, 0.1)';
    padding: '5px';

}


.buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    margin: 10px;
    height: 44px;
    grid-column: 2;
    grid-row: 1;
    justify-self: right;
}

.frame16 {
    /* Frame 16 */

    /* box-sizing: border-box; */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    gap: 8px;
    position: relative;

    width: 130px;
    height: 44px;

    background: #FFFFFF;
    border: 1px solid rgba(43, 47, 62, 0.15);
    border-radius: 8px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    /* align-self: stretch; */
    flex-grow: 0;

    transition: background-color 0.3s ease;

}

.frame16:hover {
    background: #466AE91A;
}

.frame16Text {
    /* text */

    width: auto;
    height: 18px;

    font-family: 'IBM Plex Sans' sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #252522;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .buttonGroup {
        gap: 4px;
        margin: 10px 0 10px 0;
        width: 510px;
    }

    .frame16 {
        width: 122px;
    }
}